import { ControlCommunicationService } from './../../../services/control-communication.service';
import { FormBuilderComponent } from './../../../form-builder.component'
import { CataloguesService } from './../../../../core/services/catalogues.service';
import { UnknownParams, ControlElement } from './../../../interfaces/form.interface';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
    selector: 'multiselect-element',
    templateUrl: './multi-select.component.html'
})
export class MultiSelectComponent implements OnInit {

    attributes: UnknownParams = {}
    validations: UnknownParams = {}
    options: {
        name: String,
        value: any
    }[] = []

    @Input() data: ControlElement
    @Input() form: FormGroup

    scopes = []
    sco = [["LPO - Producción vegetal", "Producción vegetal"], ["LPO - Procesamiento", "Procesamiento de productos de las actividades agropecuarias"], ["LPO - Comercialización", "Comercialización de productos de las actividades agropecuarias"], ["LPO - Producción animal (Domésticos)", "Producción animal (domésticos)"], ["LPO - Producción animal de ecosistemas", "Producción animal de ecosistemas naturales o no domésticos"], ["LPO - Fungi", "Productos de la clase fungi"], ["LPO - Recolección silvestre", "Productos vegetales de recolección silvestre"], ["OPR - Producción agrícola", "Producción agrícola (OPR)"], ["OPR - Alimentos para el ganado", "Producción de alimentos para el ganado (OPR)"], ["OPR - Producción especializada", "Producción especializada (invernaderos, miel de abeja, recolección, germinados) (OPR)"], ["OPR - Productos procesados", "Productos procesados (OPR)"]]

    // inject parent FormBuilder Component in order to access FormGroup
    constructor(
        private parentControl: FormBuilderComponent,
        private communicationService: ControlCommunicationService,
        private route: ActivatedRoute,
        private cataloguesService: CataloguesService,
    ) {
        this.communicationService.changeEmmited$.subscribe(async msg => {
            if (msg.identifier === 'scopes') {
                const variants = msg.scopes
                this.scopes = []
                for (var i in variants) {
                    const variant = variants[i]
                    switch (variant) {
                        /* version 1 */
                        case 0:
                            this.scopes.push(["LPO - Comercialización", "Comercialización de productos de las actividades agropecuarias"])
                            break
                        case 1:
                            this.scopes.push(["LPO - Procesamiento", "Procesamiento de productos de las actividades agropecuarias"])
                            break
                        case 2:
                            this.scopes.push(["LPO - Producción animal de ecosistemas", "Producción animal de ecosistemas naturales o no domésticos"])
                            break
                        case 3:
                            this.scopes.push(["LPO - Producción animal (Domésticos)", "Producción animal (domésticos)"])
                            break
                        case 4:
                            this.scopes.push(["LPO - Producción vegetal", "Producción vegetal"])
                            break
                        case 5:
                            this.scopes.push(["LPO - Fungi", "Productos de la clase fungi"])
                            break
                        case 6:
                            this.scopes.push(["LPO - Insecta", "Productos de la clase insecta"])
                            break
                        case 7:
                            this.scopes.push(["LPO - Recolección silvestre", "Productos vegetales de recolección silvestre"])
                            break
                        case 8:
                            this.scopes.push(["OPR - Alimentos para el ganado", "Producción de alimentos para el ganado (OPR)"])
                            break
                        case 9:
                            this.scopes.push(["OPR - Producción agrícola", "Producción agrícola (OPR)"])
                            break
                        case 10:
                            this.scopes.push(["OPR - Producción especializada", "Producción especializada (invernaderos, miel de abeja, recolección, germinados) (OPR)"])
                            break
                        case 11:
                            this.scopes.push(["OPR - Productos procesados", "Productos procesados (OPR)"])
                            break
                        /* version 2 */
                        case 12:
                            this.scopes.push(["LPO - Producción vegetal (Granos básicos y frutales)", "Producción vegetal (Granos básicos y frutales)"])
                            break
                        case 13:
                            this.scopes.push(["LPO - Producción vegetal (Estructuras y contenedores)", "Producción vegetal (Estructuras y contenedores)"])
                            break
                        case 14:
                            this.scopes.push(["LPO - Producción vegetal (Germinados)", "Producción vegetal (Germinados)"])
                            break
                        case 15:
                            this.scopes.push(["LPO - Productos vegetales de recolección silvestre", "Productos vegetales de recolección silvestre"])
                            break
                        case 16:
                            this.scopes.push(["LPO - Producción animal (domésticos)", "Producción animal (domésticos)"])
                            break
                        case 17:
                            this.scopes.push(["LPO - Producción animal de ecosistemas naturales o no domésticos", "Producción animal de ecosistemas naturales o no domésticos"])
                            break
                        case 18:
                            this.scopes.push(["LPO - Productos de la clase insecta", "Productos de la clase insecta"])
                            break
                        case 19:
                            this.scopes.push(["LPO - Producción animal acuícola", "Producción animal acuícola"])
                            break
                        case 20:
                            this.scopes.push(["LPO - Productos de la clase fungi", "Productos de la clase fungi"])
                            break
                        case 21:
                            this.scopes.push(["LPO - Procesamiento de productos de las actividades agropecuarias", "Procesamiento de productos de las actividades agropecuarias"])
                            break
                        case 22:
                            this.scopes.push(["LPO - Comercialización de productos de las actividades agropecuarias", "Comercialización de productos de las actividades agropecuarias"])
                            break
                        case 23:
                            this.scopes.push(["COR - Producción agrícola", "Producción agrícola"])
                            break
                        case 24:
                            this.scopes.push(["COR - Producción de alimentos para el ganado", "Producción de alimentos para el ganado"])
                            break
                        case 25:
                            this.scopes.push(["COR - Producción especializada (Invernaderos y contenedores)", "Producción especializada (Invernaderos y contenedores)"])
                            break
                        case 26:
                            this.scopes.push(["COR - Producción especializada (Apicultura)", "Producción especializada (Apicultura)"])
                            break
                        case 27:
                            this.scopes.push(["COR - Producción especializada (Vegetales de recolección silvestre)", "Producción especializada (Vegetales de recolección silvestre)"])
                            break
                        case 28:
                            this.scopes.push(["COR - Producción especializada (Germinados)", "Producción especializada (Germinados)"])
                            break
                        case 29:
                            this.scopes.push(["COR - Productos procesados", "Productos procesados"])
                            break
                        case 30:
                            this.scopes.push(["COR - Productos orgánicos", "Productos orgánicos"])
                            break
                        case 31:
                            this.scopes.push(["COR - Empaque o etiquetado de productos orgánicos", "Empaque o etiquetado de productos orgánicos"])
                            break
                    }
                }
                this.options = []
                for (var i in this.scopes) {
                    const option = this.scopes[i]
                    this.options.push({
                        name: option[0],
                        value: option[1]
                    })
                }
            } else if(msg.identifier === 'analisis') {
                this.form.patchValue({
                    informe_submuestra_1_analisis: []
                })

                this.options = msg.analisis
            }
        })
    }

    ngOnInit(): void {
        // asign parent formGroup to local formGroup
        if (!this.form) { this.form = this.parentControl.formGroup }
        // iterate and access component attributes
        for (var i in this.data.attributes) {
            const attribute = this.data.attributes[i]
            this.attributes[attribute.name] = attribute.value
        }
        for (var i in this.data.validations) {
            const validation = this.data.validations[i]
            this.validations[validation.name] = validation.value
        }

        const recordId = this.route.snapshot.params['recordId'];
        if(recordId) {
            const tmpVariants = localStorage.getItem("variant"+recordId)
            const variants = tmpVariants.split(",")
            this.scopes = []
            for (var i in variants) {
                const variant = variants[i]
                switch (parseInt(variant)) {
                    /* version 1 */
                    case 0:
                        this.scopes.push(["LPO - Comercialización", "Comercialización de productos de las actividades agropecuarias"])
                        break
                    case 1:
                        this.scopes.push(["LPO - Procesamiento", "Procesamiento de productos de las actividades agropecuarias"])
                        break
                    case 2:
                        this.scopes.push(["LPO - Producción animal de ecosistemas", "Producción animal de ecosistemas naturales o no domésticos"])
                        break
                    case 3:
                        this.scopes.push(["LPO - Producción animal (Domésticos)", "Producción animal (domésticos)"])
                        break
                    case 4:
                        this.scopes.push(["LPO - Producción vegetal", "Producción vegetal"])
                        break
                    case 5:
                        this.scopes.push(["LPO - Fungi", "Productos de la clase fungi"])
                        break
                    case 6:
                        this.scopes.push(["LPO - Insecta", "Productos de la clase insecta"])
                        break
                    case 7:
                        this.scopes.push(["LPO - Recolección silvestre", "Productos vegetales de recolección silvestre"])
                        break
                    case 8:
                        this.scopes.push(["OPR - Alimentos para el ganado", "Producción de alimentos para el ganado (OPR)"])
                        break
                    case 9:
                        this.scopes.push(["OPR - Producción agrícola", "Producción agrícola (OPR)"])
                        break
                    case 10:
                        this.scopes.push(["OPR - Producción especializada", "Producción especializada (invernaderos, miel de abeja, recolección, germinados) (OPR)"])
                        break
                    case 11:
                        this.scopes.push(["OPR - Productos procesados", "Productos procesados (OPR)"])
                        break
                    /* version 2 */
                    case 12:
                        this.scopes.push(["LPO - Producción vegetal (Granos básicos y frutales)", "Producción vegetal (Granos básicos y frutales)"])
                        break
                    case 13:
                        this.scopes.push(["LPO - Producción vegetal (Estructuras y contenedores)", "Producción vegetal (Estructuras y contenedores)"])
                        break
                    case 14:
                        this.scopes.push(["LPO - Producción vegetal (Germinados)", "Producción vegetal (Germinados)"])
                        break
                    case 15:
                        this.scopes.push(["LPO - Productos vegetales de recolección silvestre", "Productos vegetales de recolección silvestre"])
                        break
                    case 16:
                        this.scopes.push(["LPO - Producción animal (domésticos)", "Producción animal (domésticos)"])
                        break
                    case 17:
                        this.scopes.push(["LPO - Producción animal de ecosistemas naturales o no domésticos", "Producción animal de ecosistemas naturales o no domésticos"])
                        break
                    case 18:
                        this.scopes.push(["LPO - Productos de la clase insecta", "Productos de la clase insecta"])
                        break
                    case 19:
                        this.scopes.push(["LPO - Producción animal acuícola", "Producción animal acuícola"])
                        break
                    case 20:
                        this.scopes.push(["LPO - Productos de la clase fungi", "Productos de la clase fungi"])
                        break
                    case 21:
                        this.scopes.push(["LPO - Procesamiento de productos de las actividades agropecuarias", "Procesamiento de productos de las actividades agropecuarias"])
                        break
                    case 22:
                        this.scopes.push(["LPO - Comercialización de productos de las actividades agropecuarias", "Comercialización de productos de las actividades agropecuarias"])
                        break
                    case 23:
                        this.scopes.push(["COR - Producción agrícola", "Producción agrícola"])
                        break
                    case 24:
                        this.scopes.push(["COR - Producción de alimentos para el ganado", "Producción de alimentos para el ganado"])
                        break
                    case 25:
                        this.scopes.push(["COR - Producción especializada (Invernaderos y contenedores)", "Producción especializada (Invernaderos y contenedores)"])
                        break
                    case 26:
                        this.scopes.push(["COR - Producción especializada (Apicultura)", "Producción especializada (Apicultura)"])
                        break
                    case 27:
                        this.scopes.push(["COR - Producción especializada (Vegetales de recolección silvestre)", "Producción especializada (Vegetales de recolección silvestre)"])
                        break
                    case 28:
                        this.scopes.push(["COR - Producción especializada (Germinados)", "Producción especializada (Germinados)"])
                        break
                    case 29:
                        this.scopes.push(["COR - Productos procesados", "Productos procesados"])
                        break
                    case 30:
                        this.scopes.push(["COR - Productos orgánicos", "Productos orgánicos"])
                        break
                    case 31:
                        this.scopes.push(["COR - Empaque o etiquetado de productos orgánicos", "Empaque o etiquetado de productos orgánicos"])
                        break
                }
            }
            this.options = []
            for (var i in this.scopes) {
                const option = this.scopes[i]
                this.options.push({
                    name: option[0],
                    value: option[1]
                })
            }
            // if (this.attributes.options) {
            //     for (var i in this.attributes.options) {
            //         const option = this.attributes.options[i]
            //         this.options.push({
            //             name: option[0],
            //             value: option[1]
            //         })
            //     }
            // }
        } else if(this.attributes.name === "informe_submuestra_1_analisis" && this.form.value.informe_submuestra_1_laboratorio !== '') {
            var laboratorio = this.form.value.informe_submuestra_1_laboratorio

            this.cataloguesService.getOnlyLaboratories().pipe(first()).subscribe(data => {
                if(data.success) {
                  for (var i in data.data) {
                    const elem = data.data[i]
                    if(elem.Name === laboratorio) {
                      var tests = JSON.parse(elem.Tests)
                      this.options = []
                      for(var i in tests) {
                        const option = tests[i]
                        this.options.push({
                          name: option.name,
                          value: option.name
                        })
                      }

                      break
                    }
                  }
                }
              }, error => {
                console.log("Error al consultar analisis de laboratorio " + laboratorio)
              })
        }
        
        this.form.addControl(this.attributes.name, new FormControl(""))
    }

    selectedOption = [];
}
