import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class EvaluationsService {

    constructor(private http: HttpClient) { }

    getPendings() {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/evaluations/pending`, { headers });
    }

    getDone(page, search) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        const body = {
            page: page,
            search: search
        }

        return this.http.post<any>(`${environment.apiUrl}/evaluations/done`, body, { headers });
    }

    getNotAnnouncedDone(page, search) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        const body = {
            page: page,
            search: search
        }

        return this.http.post<any>(`${environment.apiUrl}/evaluations/notAnnouncedDone`, body, { headers });
    }

    assign(evaluationId, dictatorId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        const payload = {}

        return this.http.patch<any>(`${environment.apiUrl}/evaluations/${evaluationId}/assign/${dictatorId}`, payload, { headers });
    }

    getEvaluation(evaluationId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/evaluations/${evaluationId}`, { headers });
    }

    getEvaluationNotAnnounced(evaluationId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/evaluations/notAnnounced/${evaluationId}`, { headers });
    }

    generateNotAnnouncedResult(evaluationId, payload) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Authorization': jwtToken
        };

        return this.http.post<any>(`${environment.apiUrl}/evaluations/${evaluationId}/result/notAnnounced`, payload, { headers });
    }

    getForm(evaluationId, formId, isAnInspection = false, inspectionId = 0) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        const isInspection = isAnInspection ? 1 : 0


        return this.http.get<any>(`${environment.apiUrl}/evaluations/${evaluationId}/form/${formId}/${isInspection}/${inspectionId}`, { headers });
    }

    formsReview(evaluationId, formId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        const payload = {}

        return this.http.patch<any>(`${environment.apiUrl}/evaluations/${evaluationId}/form/${formId}/review`, payload, { headers });
    }

    getUrlStatus(url: string) {
        return this.http.get(url);
    }

    searchUser(query: string) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/evaluations/commitee/search/${query}`, { headers }).pipe(
            map(response => {
                //console.log(response['data'])
                return response['data']
            })
        );
        
    }

    addCommiteeUser(evaluationId, userId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        const payload = {}

        return this.http.post<any>(`${environment.apiUrl}/evaluations/${evaluationId}/commitee/add/${userId}`, payload, { headers });
    }

    removeCommiteeUser(evaluationId, userId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        const payload = {}

        return this.http.delete<any>(`${environment.apiUrl}/evaluations/${evaluationId}/commitee/remove/${userId}`, { headers });
    }

    signEvaluation(evaluationId, formId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        const payload = {}

        return this.http.patch<any>(`${environment.apiUrl}/evaluations/${evaluationId}/form/${formId}/review`, payload, { headers });
    }

    dictaminationResult(evaluationId, payload) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Authorization': jwtToken
        };

        return this.http.post<any>(`${environment.apiUrl}/evaluations/${evaluationId}/result`, payload, { headers });
    }

    getDictaminationResult(evaluationId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/evaluations/${evaluationId}/result`, { headers });
    }

    getNotAnnouncedResult(recordId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/evaluations/${recordId}/resultNotAnnounced`, { headers });
    }

    generateCertificate(recordId, version) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        const payload = {}

        return this.http.post<any>(`${environment.apiUrl}/certificates/generatePdf/${recordId}/${version}`, payload, { headers });
    }

    getDictators() {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/evaluations/dictators`, { headers });
    }

    getToValidate() {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/evaluations/toValidate`, { headers });
    }

    getExpired() {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/evaluations/expired`, { headers });
    }

    validateInspection(evaluationId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        const payload = {}

        return this.http.patch<any>(`${environment.apiUrl}/evaluations/${evaluationId}/validate`, payload, { headers });
    }

    reprogramInspection(evaluationId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        const payload = {}
        
        return this.http.delete<any>(`${environment.apiUrl}/evaluations/${evaluationId}/reprogram`, { headers });
    }

    saveForm(evaluationId, formId, form, notChange = false) {
        let body = {
            submission: []
        }

        if (!notChange) {
            for (var i in Object.keys(form)) {
                const key = Object.keys(form)[i]
                body.submission.push({
                    name: key,
                    value: form[key]
                })
            }
        } else {
            body.submission = form
        }

        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.post<any>(`${environment.apiUrl}/evaluations/${evaluationId}/form/${formId}/submit`, body, { headers });
    }



    regenerateResultList() {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/evaluations/regenerate/result/list`, { headers });
    }

    regenerateResult(IdComercial, Year, payload) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Authorization': jwtToken
        };

        return this.http.post<any>(`${environment.apiUrl}/evaluations/regenerate/result/${IdComercial}/${Year}`, payload, { headers });
    }
}