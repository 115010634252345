import { ControlCommunicationService } from './../../../services/control-communication.service';
import { FormBuilderComponent } from './../../../form-builder.component';
import { ContainerElement, UnknownParams, ControlElement } from './../../../interfaces/form.interface';
import { FormGroup, FormArray, FormControl, FormBuilder } from '@angular/forms';
import { Component, OnInit, OnDestroy, Input, Type } from '@angular/core'
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, tap, switchMap, first } from 'rxjs/operators';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

import { SubstancesService } from '../../../../core/services/substances.services'
import { AuthenticationService, UsersService, CustomersService } from '@app/core/services';
import { User } from '@app/core/models';
import { ModalPreview } from '@app/core/components/modal-preview';
import { copyFileSync } from 'fs';
import { Console } from 'console';
import { Subscription } from 'rxjs';

const MODALS: { [name: string]: Type<any> } = {
    preview: ModalPreview
};

@Component({
    selector: 'table-top-dynamic',
    templateUrl: './table-top.component.html'
})
export class tableTopDynamicComponent implements OnInit, OnDestroy {
    model: any;
    searching = false;
    searchFailed = false;

    user: User;
    type = ''

    attributes: UnknownParams = {}

    @Input() form: FormGroup
    formArray: FormArray

    keys: String[][]

    @Input() data: ContainerElement

    currentIndex = 0
    commentsToOperator = ''
    timer: any
    
    private subscriptions: Subscription
    
    constructor(
        private authenticationService: AuthenticationService,
        private substancesService: SubstancesService,
        private parentControl: FormBuilderComponent, 
        private communication: ControlCommunicationService,
        private _modalService: NgbModal,
        private usersService: UsersService,
        private customersService: CustomersService,
        private fb: FormBuilder,
    ) { 
        this.user = this.authenticationService.userValue;
        switch (this.user.type) {
            case "ROOT":
                this.type = 'Administrador'
                break;
            case "OPERADOR":
                this.type = 'Operador'
                break;
            case "CAPTURISTA":
                this.type = 'Capturista'
                break;
            case "RESPONSABLE":
                this.type = 'Responsable'
                break;
            case "ADMINISTRADOR":
                this.type = 'Administrador'
                break;
            case "DIRECTOR_CERTIFICACION":
                this.type = 'Supervisor'
                break;
            case "REVISOR":
                this.type = 'Revisor'
                break;
            case "INSPECTOR":
                this.type = 'Inspector'
                break;
            case "DICTAMINADOR":
                this.type = 'Dictaminador'
                break;
            case "OBSERVADOR":
                this.type = 'Observador'
                break;
            default:
                break;
        }
    }

    ngOnInit() {
        // Create objects containing attributes and validations
        for (var i in this.data.attributes) {
            const attribute = this.data.attributes[i]
            this.attributes[attribute.name] = attribute.value
        }
        if (!this.form) { this.parentControl.formGroup }
        this.form.addControl(this.attributes.group_name, new FormArray([
            new FormGroup({})
        ]))
        this.formArray = this.form.get(this.attributes.group_name) as FormArray
        let keys = []
        for (var i in this.formArray.controls) {
            const formGroup: FormGroup = this.formArray.controls[i] as FormGroup
            keys.push(Object.keys(formGroup.controls))
            //this.currentIndex = parseInt(i)
        }
        this.keys = keys
        this.subscriptions = this.communication.changeEmmited$.subscribe(msg => {
            //console.log("4", msg)
            if (msg.identifier == "set-values") {
                //console.log("3", msg)
                this.formArray = this.form.get(this.attributes.group_name) as FormArray
            }
        })
    }
    
    ngOnDestroy() {
        this.subscriptions.unsubscribe()
    }

    addFormGroup(event) {
        event.preventDefault()
        this.currentIndex = this.formArray.length - 1
        if(isNaN(this.currentIndex)) {
            this.formArray = this.fb.array([])
            this.formArray.push(new FormGroup({}))
            this.currentIndex = this.formArray.length - 1
            setTimeout(() => {
                document.getElementById('dynamic-container').scroll({
                    behavior: 'smooth',
                    top: document.getElementById('dynamic-container').scrollHeight
                })
            }, 100);
            let keys = []
            for (var i in this.formArray.controls) {
                const formGroup: FormGroup = this.formArray.controls[i] as FormGroup
                keys.push(Object.keys(formGroup.controls))
            }
            this.keys = keys

            this.form.setControl(this.attributes.group_name, this.formArray)
        } else if (this.currentIndex !== this.formArray.length - 1) {
            this.currentIndex = this.formArray.length - 1
        } else {
            this.formArray.push(new FormGroup({}))
            this.currentIndex = this.formArray.length - 1
            setTimeout(() => {
                document.getElementById('dynamic-container').scroll({
                    behavior: 'smooth',
                    top: document.getElementById('dynamic-container').scrollHeight
                })
            }, 100);
            let keys = []
            for (var i in this.formArray.controls) {
                const formGroup: FormGroup = this.formArray.controls[i] as FormGroup
                keys.push(Object.keys(formGroup.controls))
            }
            this.keys = keys
        }
    }

    changeIndex(index) {
        this.currentIndex = index

        this.communication.emitEvent({
            identifier: 'update-substance-index',
            substanceIndex: index,
            text: "no"
        })
    }

    deleteGroup(index) {
        this.formArray.removeAt(index)
        let keys = []
        for (var i in this.formArray.controls) {
            const formGroup: FormGroup = this.formArray.controls[i] as FormGroup
            keys.push(Object.keys(formGroup.controls))
        }
        this.keys = keys
        this.currentIndex = this.formArray.length - 1
    }

    search = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            tap(() => this.searching = true),
            switchMap(term =>
            this.substancesService.searchSubstancesOperator(term).pipe(
                tap(() => this.searchFailed = false),
                catchError(() => {
                this.searchFailed = true;
                return of([]);
                }))
            ),
            tap(() => this.searching = false)
        )

    formatter = (x: {code: string, name: string} ) => null

    async loadSubstance(event) {
        event.preventDefault()
        //console.log(event)
        //console.log(this.formArray)

        /*let controls = this.formArray.controls.length
        for (let i = 0; i < controls; i++) {
            this.deleteGroup(i)            
        }*/

        await event.item.substances.forEach(element => {
            element.uso_abono_fungicida_etc = ""
            element.cultivo_area_aplicacion = ""
        });

        let lastForm = {}
        lastForm[this.attributes.group_name] = event.item.substances

        //console.log(lastForm, "lastForm")

        setTimeout(() => {
            this.communication.emitEvent({
                identifier: 'set-values',
                form: lastForm,
                text: "no"
            })
        }, 100);

        this.changeIndex(0)
    }

    save(index){
        this.communication.emitEvent({
            identifier: 'save-substance',
            substanceIndex: index,
            text: "no"
        })
    }
}