import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService, AuthenticationService, EvaluationsService } from '@app/core/services'
import { first } from 'rxjs/operators';

@Component({
    selector: 'modal-changeDictator',
    templateUrl: 'modal-changeDictator.component.html',
    styleUrls: ['../../../app.component.scss']
})
export class ModalChangeDictator implements OnInit {
    form: FormGroup;
    @Input() public name = ''
    @Input() public id

    loading = false;
    submitted = false;
    res = false
    dictators = []
    tmpName = ''

    constructor(
        private formBuilder: FormBuilder,
        public modal: NgbActiveModal,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private evaluationsService: EvaluationsService
    ) { }
    ngOnInit() {
        if(name) {
            this.tmpName = name
        }
        else {
            this.tmpName = ''
        }

        this.evaluationsService.getDictators()
            .pipe(first())
            .subscribe(
                response => {
                    console.log(response)

                    this.dictators = response.data
                },
                error => {
                    this.alertService.error(error)
                })

        this.form = this.formBuilder.group({
            newDictator: ['', Validators.required],
        });

    }

    // Convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        this.submitted = true;

        // Reset alerts on submit
        this.alertService.clear();

        // Stop here if form is invalid
        if (this.form.invalid) {
            return;
        }
        if (this.form.value.newDictator) {
            this.loading = true;
            this.evaluationsService.assign(this.id, this.form.value.newDictator)
                .pipe(first())
                .subscribe(
                    response => {
                        console.log(response)
                        this.alertService.clear()
                        if (response.success) {
                            this.alertService.success('Se ha asignado el dictaminador correctamente', { autoClose: true })
                            this.modal.close(true)
                            this.modal.dismiss()
                        } else {
                        }
                        this.loading = false;
                    },
                    error => {
                        this.alertService.error(error);
                        this.loading = false;
                    });
        }
    }
}