import { Component, Input, OnInit } from '@angular/core';
import { AlertService, AuthenticationService, RequestsService } from '@app/core/services'
import { signDictator } from '../../../features/generator/signDictator'

@Component({
    selector: 'contract-mx',
    templateUrl: 'contract_v1.component.html',
    styleUrls: ['../../../app.component.scss']
})
export class ContractMX implements OnInit {
    @Input() public name = ''
    @Input() public date
    @Input() public customer
    @Input() public metrocert
    @Input() public signOperator = ''

    signDictator = signDictator

    res = false
    loading = false

    constructor(
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private requestsService: RequestsService
    ) { 
    }

    ngOnInit() {

    }
}

@Component({
    selector: 'contract-mx-v2',
    templateUrl: 'contract_v2.component.html',
    styleUrls: ['../../../app.component.scss']
})
export class ContractMXV2 implements OnInit {
    @Input() public name = ''
    @Input() public date
    @Input() public customer
    @Input() public metrocert
    @Input() public signOperator = ''

    signDictator = signDictator

    res = false
    loading = false

    constructor(
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private requestsService: RequestsService
    ) { 
    }

    ngOnInit() {

    }
}