import { readFileSync } from 'fs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class InspectionsService {

    constructor(private http: HttpClient) { }

    getPendings() {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/inspections/pending`, { headers });
    }

    getAssigned() {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/inspections/programmed`, { headers });
    }

    getChecklist() {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/inspections/checklist`, { headers });
    }

    getNotAnnounced() {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/inspections/getNotAnnounced`, { headers });
    }

    getCurrent(inspectionId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/inspections/${inspectionId}`, { headers });
    }

    getInspectors() {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/inspections/inspectors`, { headers });
    }

    changeInspector(inspectorId, inspectionId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        const payload = {}

        return this.http.patch<any>(`${environment.apiUrl}/inspections/inspector/${inspectorId}/assign/${inspectionId}`, payload, { headers });
    }

    getInspectionToChecklist(inspectionId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/inspections/${inspectionId}/checklist`, { headers });
    }

    sendDecisionChecklist(inspectionId, decision, data) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        const payload = {
            order: data.order,
            informe: data.informe,
            anexos: data.anexos,
            others: data.others
        }

        return this.http.patch<any>(`${environment.apiUrl}/inspections/${inspectionId}/checklist/${decision}`, payload, { headers });
    }

    assign(inspectionId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        const payload = {}

        return this.http.patch<any>(`${environment.apiUrl}/inspections/${inspectionId}/assign`, payload, { headers });
    }

    acceptInspection(inspectionId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-type': 'application/json',
            'Authorization': jwtToken
        };

        const payload = {}
        
        return this.http.post<any>(`${environment.apiUrl}/inspections/${inspectionId}/accept`, payload, { headers });
    }

    denyInspection(inspectionId, comment) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-type': 'application/json',
            'Authorization': jwtToken
        };

        const payload = {
            comments: comment
        }
        
        return this.http.post<any>(`${environment.apiUrl}/inspections/${inspectionId}/deny`, payload, { headers });
    }

    uploadPreview(file: Blob, ext: string) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'authorization': jwtToken
        };

        const payload = new FormData()
        payload.append('file', file, 'file.' + ext)

        return this.http.post<any>(`${environment.apiUrl}/inspections/preview`, payload, { headers });
    }

    getInspectionData(inspectionId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/inspections/inspection/${inspectionId}`, { headers });
    }

    signinspection(inspectionId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.patch<any>(`${environment.apiUrl}/inspections/${inspectionId}/sign`, {}, { headers });
    }

    inspectionComplete(inspectionId, values) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        const payload = values

        return this.http.patch<any>(`${environment.apiUrl}/inspections/inspection/${inspectionId}/complete`, payload, { headers });
    }

    getOrderForms(version) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/inspections/order/forms/${version}`, { headers });
    }

    getPlanForms(inspectionId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/inspections/${inspectionId}/plan`, { headers });
    }

    getPlanOnly(inspectionId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/inspections/${inspectionId}/inspectionplan`, { headers });
    }

    getPlanOnlySubmission(inspectionId, formId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/inspections/${inspectionId}/inspectionplan/submission/${formId}`, { headers });
    }

    getPlanForm(inspectionId, formId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/inspections/${inspectionId}/plan/${formId}`, { headers });
    }

    getForm(inspectionId, formId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/inspections/${inspectionId}/form/${formId}`, { headers });
    }

    saveForm(inspectionId, formId, form, dates, inspector, responsible?, responsibleId?, notChange = false) {
        let body = {
            submission: []
        }

        if (!notChange) {
            for (var i in Object.keys(form)) {
                const key = Object.keys(form)[i]
                body.submission.push({
                    name: key,
                    value: form[key]
                })
            }
        } else {
            body.submission = form
        }

        if (dates) {
            for (var i in Object.keys(dates)) {
                const key = Object.keys(dates)[i]
                body.submission.push({
                    name: key,
                    value: dates[key]
                })
            }
        }

        if (inspector) {
            body.submission.push({
                name: 'inspector',
                value: inspector
            })
        }

        if (responsible) {
            body.submission.push({
                name: 'responsible',
                value: responsible
            })

            body.submission.push({
                name: 'responsibleId',
                value: responsibleId
            })
        }

        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.post<any>(`${environment.apiUrl}/inspections/${inspectionId}/form/${formId}/submit`, body, { headers });
    }

    saveTypeInspection(inspectionId, type) {
        let body = {
            type: type
        }

        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.post<any>(`${environment.apiUrl}/inspections/${inspectionId}/assingtype`, body, { headers });
    }

    saveFormOffline(inspectionId, formId, form, dates, inspector) {
        let body = {
            submission: form
        }

        if (inspector) {
            body.submission.push({
                name: 'inspector',
                value: inspector
            })
        }
        //console.log(body)

        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.post<any>(`${environment.apiUrl}/inspections/${inspectionId}/form/${formId}/submit`, body, { headers });
    }

    uploadInspectionPlan(inspectionId: number, file: Blob) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Authorization': jwtToken
        };

        const body = new FormData()
        body.append('file', file, 'file.docx')

        return this.http.post<any>(`${environment.apiUrl}/inspections/${inspectionId}/upload`, body, { headers });
    }

    getInspectionPlanFile(inspectionId) {
        return this.http.get<any>(`${environment.apiUrl}/inspections/${inspectionId}/file`);
    }

    getUrlStatus(url: string) {
        return this.http.get(url);
    }

    getLastNoConformidades(inspectionId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/inspections/${inspectionId}/getLatestNoConformities`, { headers });
    }

    getResultReview(recordId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/inspections/${recordId}/reviewResults`, { headers });
    }
}