import { AlignmentType, Document, Paragraph, TextRun, Media, Header, Footer, Table, TableRow, TableCell, VerticalAlign, WidthType, PageNumber, PageBreak } from "docx";
import { logo } from "../logo";
import { userWithoutSign } from "../userWithoutSign"
import { DatePipe } from '@angular/common';

export class DocumentCreatorMTO_FINS_01_v2 {

    public getBase64FromUrl = async (url) => {
        const data = await fetch(url, {
            headers: {
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
                'If-Modified-Since': '0'
            }
        });
        const blob = await data.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob); 
            reader.onloadend = function() {
                const base64data = reader.result;   
                resolve(base64data);
            }
        });
    }

    // INIT MTO_FINS_01
    public async create_MTO_FINS_01(data, signature = '', signature2 = ''): Promise<Document> {
        const document = new Document();
        const image = Media.addImage(document, logo, 50, 50);
        var datePipe = new DatePipe('es-MX');
        let signatureImage = null
        let signatureImage2 = null
        if (signature) {
            signatureImage = await this.getBase64FromUrl(signature)
        } else {
            signatureImage = userWithoutSign
        }

        if (signature2) {
            signatureImage2 = await this.getBase64FromUrl(signature2)
        } else {
            signatureImage2 = userWithoutSign
        }

        document.addSection({
            headers: {
                default: new Header({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER, children: [image], }),
                        new Paragraph({
                            alignment: AlignmentType.CENTER, children: [
                                new TextRun({
                                    children: [`MTO-FINS-01 | R 04 | 28-Septiembre-2021 | Pág. `, PageNumber.CURRENT],
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true
                                }),
                                new TextRun({
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true,
                                    children: [' de ', PageNumber.TOTAL_PAGES]
                                }),
                            ], spacing: { before: 200, after: 100 }
                        })
                    ]
                })
            },
            footers: {
                default: new Footer({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER }),
                        this.createFooter()
                    ]
                }),
            },
            children: [
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: 'ORDEN DE INSPECCIÓN ORGÁNICA',
                            font: 'Calibri (Cuerpo)',
                            size: 22,
                            bold: true
                        })
                    ],
                    spacing: { after: 200 }
                }),
                this.createTableUDP(data, datePipe),
                new Paragraph({
                    children: [
                        new PageBreak()
                    ],
                    spacing: {
                        after: 0,
                        before: 0
                    }
                }),
                this.createTableParcelas(data),
                new Paragraph({
                    spacing: {
                        after: 0,
                        before: 0
                    }
                }),
                this.createTablePecuarias(data),
                new Paragraph({
                    spacing: {
                        after: 0,
                        before: 0
                    }
                }),
                this.createTableProduccion(data),
                new Paragraph({
                    spacing: {
                        after: 0,
                        before: 0
                    }
                }),
                this.createTableIndicacionesEspecificas(data),
                new Paragraph({
                    spacing: {
                        after: 0,
                        before: 0
                    }
                }),
                this.createTableAccionesCorrectivas(data),
                new Paragraph({
                    spacing: {
                        after: 0,
                        before: 0
                    }
                }),
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        signatureImage ? Media.addImage(document, signatureImage.toString(), 100, 50) : new TextRun({
                            text: ''
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: `Fecha: ${datePipe.transform(new Date(data.inspectionDate), 'd-MMMM-y')}                  Nombre y firma del responsable: ${this.getValue(data.submission, "responsible")}`,
                            font: 'Arial',
                            size: 20,
                            italics: true
                        })
                    ],
                    spacing: {
                        after: 200
                    }
                }),
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        signatureImage2 ? Media.addImage(document, signatureImage2.toString(), 100, 50) : new TextRun({
                            text: ''
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: `Fecha: ${datePipe.transform(new Date(data.inspectionDate), 'd-MMMM-y')}                  Nombre y firma del inspector: ${this.getValue(data.submission, 'inspector')}`,
                            font: 'Arial',
                            size: 20,
                            italics: true
                        })
                    ],
                    spacing: {
                        after: 200
                    }
                }),
            ],
            margins: {
                top: 350,
                bottom: 500,
                left: 1000,
                right: 1000
            }
        });

        return document;
    }

    public createFooter(): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Elaboró:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Revisó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Autorizó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director de certificación',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Responsable de calidad',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director general',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            columnWidths: [3213, 3213, 3212]
        })
        return table
    }

    public createTableUDP(data, datePipe): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Inspector: ' + this.getValue(data.submission, 'inspector'),
                                            font: 'Calibri (Cuerpo)',
                                            size: 20,

                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 3
                        }),
                    ],
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Periodo para llevar a cabo la inspección',
                                            font: 'Calibri (Cuerpo)',
                                            size: 20,

                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: 'Desde: ' + datePipe.transform(this.getValue(data.submission, 'desde'), 'd-MMMM-y'),
                                        font: 'Calibri (Cuerpo)',
                                        size: 20,
                                    })
                                ],
                            })],
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Hasta: ' + datePipe.transform(this.getValue(data.submission, 'hasta'), 'd-MMMM-y'),
                                            font: 'Calibri (Cuerpo)',
                                            size: 20
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE,
                            },
                        })
                    ],
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'I. DATOS DEL OPERADOR',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 75,
                                        before: 75
                                    }
                                }),
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 3,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Nombre del propietario (persona física) o razón social (persona moral): ' + this.getValue(data.submission, 'nombre_propietario'),
                                            font: 'Calibri (Cuerpo)',
                                            size: 20,

                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 3
                        }),
                    ],
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Domicilio físico de la operación (lugar donde se llevará a cabo la inspección)',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 75,
                                        before: 75
                                    }
                                }),
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 3,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Calle, No. exterior, e interior: ' + data.customer['Address_2'],
                                            font: 'Calibri (Cuerpo)',
                                            size: 20,

                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: 'Colonia: ' + data.customer['Address_1'],
                                        font: 'Calibri (Cuerpo)',
                                        size: 20,
                                    })
                                ],
                            })],
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Delegación/Municipio: ' + data.customer['Town'].split("/")[1],
                                            font: 'Calibri (Cuerpo)',
                                            size: 20
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE,
                            },
                        })
                    ],
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Localidad: ' + data.customer['City'],
                                            font: 'Calibri (Cuerpo)',
                                            size: 20,

                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: 'C.P.: ' + data.customer['Zip_Code'],
                                        font: 'Calibri (Cuerpo)',
                                        size: 20,
                                    })
                                ],
                            })],
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Entidad Federativa: ' + data.customer['State'].split("/")[1],
                                            font: 'Calibri (Cuerpo)',
                                            size: 20
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE,
                            },
                        })
                    ],
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Teléfono: ' + data.customer['Phone_Number'],
                                            font: 'Calibri (Cuerpo)',
                                            size: 20
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Email: ' + data.customer['Email'],
                                            font: 'Calibri (Cuerpo)',
                                            size: 20
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE,
                            },
                            columnSpan: 2
                        })
                    ],
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Persona responsable de dar seguimiento a la certificación: ' + data.followup['Name'],
                                            font: 'Calibri (Cuerpo)',
                                            size: 20,

                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: 'Tel: ' + data.followup['Phone_Number'],
                                        font: 'Calibri (Cuerpo)',
                                        size: 20,
                                    })
                                ],
                            })],
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Correo electrónico: ' + data.followup['Email'],
                                            font: 'Calibri (Cuerpo)',
                                            size: 20
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE,
                            },
                        })
                    ],
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'II. DATOS GENERALES (Alcance: Producto y Proceso a certificar)',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 75,
                                        before: 75
                                    }
                                }),
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 3,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Tipo de inspección:',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        before: 75
                                    }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'tipo_inspeccion') === "Renovación" ? '■' : '❏'} Renovación`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'tipo_inspeccion') === "Primer Inspección" ? '■' : '❏'} Primer inspección`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'tipo_inspeccion') === "Adicional" ? '■' : '❏'} Adicional`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'tipo_inspeccion') === "Anunciada" ? '■' : '❏'} Anunciada`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'tipo_inspeccion') === "No Anunciada" ? '■' : '❏'} No Anunciada`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                }),
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Categoría de certificación LPO',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        before: 75
                                    }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'opt13_cat') ? '■' : '❏'} Producción vegetal`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `         ${this.getValue(data.submission, 'opt14_cat') ? '■' : '❏'} Granos básicos y frutales`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `         ${this.getValue(data.submission, 'opt15_cat') ? '■' : '❏'} Estructuras y contenedores`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `         ${this.getValue(data.submission, 'opt16_cat') ? '■' : '❏'} Germinados`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'opt17_cat') ? '■' : '❏'} Productos vegetales de recolección silvestre`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'opt18_cat') ? '■' : '❏'} Producción animal (domésticos)`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'opt19_cat') ? '■' : '❏'} Producción animal de ecosistemas naturales o no domésticos`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'opt20_cat') ? '■' : '❏'} Productos de la clase insecta`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'opt21_cat') ? '■' : '❏'} Producción animal acuícola`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'opt22_cat') ? '■' : '❏'} Productos de la clase fungi`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'opt23_cat') ? '■' : '❏'} Procesamiento de productos de las actividades agropecuarias`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'opt24_cat') ? '■' : '❏'} Comercialización de productos de las actividades agropecuarias`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP,
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Normas a evaluar:',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        before: 75
                                    }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'opt1') ? '■' : '❏'} LPO`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'opt2') ? '■' : '❏'} COR/USCOEA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Categoría de certificación COR/USCOEA (Canadá/Estados Unidos)',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        before: 75
                                    }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'opt25_cat') ? '■' : '❏'} Producción agrícola`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'opt26_cat') ? '■' : '❏'} Producción de alimentos para el ganado`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'opt27_cat') ? '■' : '❏'} Producción especializada`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `         ${this.getValue(data.submission, 'opt28_cat') ? '■' : '❏'} Invernaderos y contenedores`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `         ${this.getValue(data.submission, 'opt29_cat') ? '■' : '❏'} Apicultura`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `         ${this.getValue(data.submission, 'opt30_cat') ? '■' : '❏'} Vegetales de recolección silvestre`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `         ${this.getValue(data.submission, 'opt31_cat') ? '■' : '❏'} Germinados`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'opt32_cat') ? '■' : '❏'} Productos procesados`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP,
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Tipo de operador:',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        before: 75
                                    }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${data.operatorType === 'INDIVIDUAL' ? '■' : '❏'} Individual`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${data.operatorType === 'EMPRESA' ? '■' : '❏'} Empresa`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${data.operatorType === 'GRUPO' ? '■' : '❏'} Grupo de productores`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                }),
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Subdivisión COR/USCOEA (Canadá/Estados Unidos)',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        before: 75
                                    }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'opt33_cat') ? '■' : '❏'} Productos orgánicos`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'opt34_cat') ? '■' : '❏'} Empaque o etiquetado de productos orgánicos`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                }),
                            ],
                            verticalAlign: VerticalAlign.TOP,
                            columnSpan: 2
                        })
                    ]
                }),
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            columnWidths: [3312, 3312, 3312, 3313]
        })
        return table
    }

    public createTableParcelas(data): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Parcelas a inspeccionar:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 6,
                        }),
                    ],
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'No',
                                            font: 'Calibri (Cuerpo)',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Nombre de la Parcela',
                                            font: 'Calibri (Cuerpo)',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Ubicación',
                                            font: 'Calibri (Cuerpo)',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Superficie (ha)',
                                            font: 'Calibri (Cuerpo)',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Producto',
                                            font: 'Calibri (Cuerpo)',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Producción anual esperada (Kg)',
                                            font: 'Calibri (Cuerpo)',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ],
                }),
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            columnWidths: [522, 2119, 2023, 1523, 1723, 2023]
        })

        const parcelas = this.getValueList(data.submission, 'lista-parcelas')
        parcelas.forEach((parcela, index) => {
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: index + 1,
                                            font: 'Calibri (Cuerpo)',
                                            size: 20
                                        })
                                    ]
                                })],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: parcela['nombre_unidad_produccion'],
                                            font: 'Calibri (Cuerpo)',
                                            size: 20
                                        })
                                    ]
                                })],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: parcela['b'],
                                            font: 'Calibri (Cuerpo)',
                                            size: 20
                                        })
                                    ]
                                })],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: parcela['superficie_total_hectareas'],
                                            font: 'Calibri (Cuerpo)',
                                            size: 20
                                        })
                                    ]
                                })],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: parcela['orientacion_productiva'],
                                            font: 'Calibri (Cuerpo)',
                                            size: 20
                                        })
                                    ]
                                })],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: parcela['cultivo_estimachon_cosechas_kg'],
                                            font: 'Calibri (Cuerpo)',
                                            size: 20
                                        })
                                    ]
                                })],
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ],
                })
            )
        })

        table.addChildElement(
            new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: 'GRUPO DE PRODUCTORES',
                                        font: 'Calibri (Cuerpo)',
                                        size: 20
                                    })
                                ]
                            })],
                        verticalAlign: VerticalAlign.CENTER,
                        columnSpan: 2
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: 'No de Productores: ' + parseInt(this.getValue(data.submission, 'no_productores')),
                                        font: 'Calibri (Cuerpo)',
                                        size: 20
                                    })
                                ]
                            })],
                        verticalAlign: VerticalAlign.CENTER,
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: 'Factor de Riesgo: ' + this.getValue(data.submission, 'factor_riesgo'),
                                        font: 'Calibri (Cuerpo)',
                                        size: 20
                                    })
                                ]
                            })],
                        verticalAlign: VerticalAlign.CENTER,
                        columnSpan: 2
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: 'Número de miembros a inspeccionar: ' + parseInt(this.getValue(data.submission, 'no_miembros')),
                                        font: 'Calibri (Cuerpo)',
                                        size: 20
                                    })
                                ]
                            })],
                        verticalAlign: VerticalAlign.CENTER,
                        columnSpan: 1
                    })
                ],
            }),
        )

        return table
    }

    public createTablePecuarias(data): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Unidades de producción pecuaria a inspeccionar:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 7,
                        }),
                    ],
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'No',
                                            font: 'Calibri (Cuerpo)',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Nombre de la unidad',
                                            font: 'Calibri (Cuerpo)',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Ubicación',
                                            font: 'Calibri (Cuerpo)',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Superficie (ha)',
                                            font: 'Calibri (Cuerpo)',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Especie',
                                            font: 'Calibri (Cuerpo)',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Cabezas (UG)',
                                            font: 'Calibri (Cuerpo)',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Producto',
                                            font: 'Calibri (Cuerpo)',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Producción anual esperada (Kg)',
                                            font: 'Producción anual esperada (Kg)',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                    ],
                }),
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            columnWidths: [541,1541,1241,1241,1441,1241,1441,1241]
        })

        // TODO: Añadir lista de producción animal
        const pecuarias = this.getValueList(data.submission, 'lista-producccion-pecuaria')
        pecuarias.forEach((pecuaria, index) => {
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: index + 1,
                                            font: 'Calibri (Cuerpo)',
                                            size: 20
                                        })
                                    ]
                                })],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: pecuaria['nombre_unidad_produccion'],
                                            font: 'Calibri (Cuerpo)',
                                            size: 20
                                        })
                                    ]
                                })],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: pecuaria['domicilio_municipio'],
                                            font: 'Calibri (Cuerpo)',
                                            size: 20
                                        })
                                    ]
                                })],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: pecuaria['cc'],
                                            font: 'Calibri (Cuerpo)',
                                            size: 20
                                        })
                                    ]
                                })],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: pecuaria['lista_productos_variedad'],
                                            font: 'Calibri (Cuerpo)',
                                            size: 20
                                        })
                                    ]
                                })],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: pecuaria['lista_productos_hectareas'],
                                            font: 'Calibri (Cuerpo)',
                                            size: 20
                                        })
                                    ]
                                })],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: pecuaria['lista_productos_producto'],
                                            font: 'Calibri (Cuerpo)',
                                            size: 20
                                        })
                                    ]
                                })],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: pecuaria['lista_productos_produccion_anual'],
                                            font: 'Calibri (Cuerpo)',
                                            size: 20,
                                        })
                                    ]
                                })],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                    ],
                })
            )
        })

        return table
    }

    public createTableProduccion(data): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Unidades de Proceso a inspeccionar',
                                            font: 'Calibri (Cuerpo)',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 7,
                        }),
                    ],
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'No',
                                            font: 'Calibri (Cuerpo)',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Nombre de la unidad',
                                            font: 'Calibri (Cuerpo)',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Ubicación',
                                            font: 'Calibri (Cuerpo)',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Principales procesos',
                                            font: 'Calibri (Cuerpo)',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Productos',
                                            font: 'Calibri (Cuerpo)',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ],
                }),
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            columnWidths: [586, 2486, 1986, 2486, 2386]
        })

        // TODO: Añadir lista de producción
        let produccion = this.getValueList(data.submission, 'lista-producccion-proceso')
        produccion = produccion.filter(elem => {
            if (elem['nombre_unidad_produccion']) return true
            return false
        })
        produccion.forEach((prod, index) => {
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: index + 1,
                                            font: 'Calibri (Cuerpo)',
                                            size: 20
                                        })
                                    ]
                                })],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: prod['nombre_unidad_produccion'],
                                            font: 'Calibri (Cuerpo)',
                                            size: 20
                                        })
                                    ]
                                })],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: prod['domicilio_calle'] + ' ' + prod['domicilio_colonia'] + ' ' + prod['domicilio_localidad'] + ' ' + prod['domicilio_municipio'] + ' ' + prod['domicilio_estado'] + ' ' + prod['domicilio_cp'],
                                            font: 'Calibri (Cuerpo)',
                                            size: 20
                                        })
                                    ]
                                })],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: prod['ccc'],
                                            font: 'Calibri (Cuerpo)',
                                            size: 20
                                        })
                                    ]
                                })],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: prod['ddd'],
                                            font: 'Calibri (Cuerpo)',
                                            size: 20
                                        })
                                    ]
                                })],
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ],
                })
            )
        })

        return table
    }

    public createTableIndicacionesEspecificas(data) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'III. Indicaciones específicas',
                                            font: 'Calibri (Cuerpo)',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 2,
                        }),
                    ],
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'No',
                                            font: 'Calibri (Cuerpo)',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Actividad específica',
                                            font: 'Calibri (Cuerpo)',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ],
                }),
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            columnWidths: [500, 9433]
        })

        const actividades = this.getValueList(data.submission, 'lista-indicaciones-especificas')
        actividades.forEach((actividad, index) => {
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: index + 1,
                                            font: 'Calibri (Cuerpo)',
                                            size: 20
                                        })
                                    ]
                                })],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: actividad['actividad_nombre'],
                                            font: 'Calibri (Cuerpo)',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ],
                })
            )
        })

        return table
    }

    public createTableAccionesCorrectivas(data) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'IV. Verificación de aplicación de Acciones correctivas previas',
                                            font: 'Calibri (Cuerpo)',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 2,
                        }),
                    ],
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'No',
                                            font: 'Calibri (Cuerpo)',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Acción correctiva',
                                            font: 'Calibri (Cuerpo)',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Origen',
                                            font: 'Calibri (Cuerpo)',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ],
                }),
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            columnWidths: [500, 4716, 4716]
        })

        const acciones = this.getValueList(data.submission, 'lista-acciones-correctivas')
        acciones.forEach((accion, index) => {
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: index + 1,
                                            font: 'Calibri (Cuerpo)',
                                            size: 20
                                        })
                                    ]
                                })],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: accion['aaaa'],
                                            font: 'Calibri (Cuerpo)',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: accion['bbbb'],
                                            font: 'Calibri (Cuerpo)',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ],
                })
            )
        })

        return table
    }

    getValue(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value !== null || typeof value !== 'undefined') return value
        else return ''
    }

    getValueList(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return []
    }
    // END MTO_FINS_01
}