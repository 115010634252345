import { ControlCommunicationService } from './../../../services/control-communication.service';
import { FormBuilderComponent } from './../../../form-builder.component';
import { ContainerElement, UnknownParams, ControlElement } from './../../../interfaces/form.interface';
import { FormGroup, FormArray, FormControl } from '@angular/forms';
import { Component, OnInit, OnDestroy, Input, Type } from '@angular/core'
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, tap, switchMap, first } from 'rxjs/operators';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

import { SubstancesService } from '../../../../core/services/substances.services'
import { AuthenticationService, UsersService, CustomersService } from '@app/core/services';
import { User } from '@app/core/models';
import { ModalPreview } from '@app/core/components/modal-preview';
import { copyFileSync } from 'fs';
import { Console } from 'console';

const MODALS: { [name: string]: Type<any> } = {
    preview: ModalPreview
};

@Component({
    selector: 'table-top-substances-dynamic',
    templateUrl: './table-top-substances.component.html'
})
export class tableTopSubstancesDynamicComponent implements OnInit {
    model: any;
    searching = false;
    searchFailed = false;

    user: User;
    type = ''

    attributes: UnknownParams = {}

    @Input() form: FormGroup
    formArray: FormArray

    keys: String[][]

    @Input() data: ContainerElement

    currentIndex = 0
    commentsToOperator = ''
    timer: any
    
    constructor(
        private authenticationService: AuthenticationService,
        private substancesService: SubstancesService,
        private parentControl: FormBuilderComponent, 
        private communication: ControlCommunicationService,
        private _modalService: NgbModal,
        private usersService: UsersService,
        private customersService: CustomersService,
    ) { 
        this.user = this.authenticationService.userValue;
        switch (this.user.type) {
            case "ROOT":
                this.type = 'Administrador'
                break;
            case "OPERADOR":
                this.type = 'Operador'
                break;
            case "CAPTURISTA":
                this.type = 'Capturista'
                break;
            case "RESPONSABLE":
                this.type = 'Responsable'
                break;
            case "ADMINISTRADOR":
                this.type = 'Administrador'
                break;
            case "DIRECTOR_CERTIFICACION":
                this.type = 'Supervisor'
                break;
            case "REVISOR":
                this.type = 'Revisor'
                break;
            case "INSPECTOR":
                this.type = 'Inspector'
                break;
            case "DICTAMINADOR":
                this.type = 'Dictaminador'
                break;
            case "OBSERVADOR":
                this.type = 'Observador'
                break;
            default:
                break;
        }
    }

    ngOnInit() {
        // Create objects containing attributes and validations
        for (var i in this.data.attributes) {
            const attribute = this.data.attributes[i]
            this.attributes[attribute.name] = attribute.value
        }
        if (!this.form) { this.parentControl.formGroup }
        this.form.addControl(this.attributes.group_name, new FormArray([
            new FormGroup({})
        ]))
        this.formArray = this.form.get(this.attributes.group_name) as FormArray
        let keys = []
        for (var i in this.formArray.controls) {
            const formGroup: FormGroup = this.formArray.controls[i] as FormGroup
            keys.push(Object.keys(formGroup.controls))
            //this.currentIndex = parseInt(i)
        }
        this.keys = keys
        this.communication.changeEmmited$.subscribe(msg => {
            if (msg.identifier == "set-values") {
                this.formArray = this.form.get(this.attributes.group_name) as FormArray
            }
        })
    }

    addFormGroup(event) {
        event.preventDefault()
        this.currentIndex = this.formArray.length - 1
        if (this.currentIndex !== this.formArray.length - 1) {
            this.currentIndex = this.formArray.length - 1
        } else {
            this.formArray.push(new FormGroup({}))
            this.currentIndex = this.formArray.length - 1
            setTimeout(() => {
                document.getElementById('dynamic-container').scroll({
                    behavior: 'smooth',
                    top: document.getElementById('dynamic-container').scrollHeight
                })
            }, 100);
            let keys = []
            for (var i in this.formArray.controls) {
                const formGroup: FormGroup = this.formArray.controls[i] as FormGroup
                keys.push(Object.keys(formGroup.controls))
            }
            this.keys = keys
        }
    }

    changeIndex(index) {
        if(this.formArray.controls[this.currentIndex].value.hasOwnProperty("correcciones")) {
            if(this.formArray.controls[this.currentIndex].value.ncNotificationSent && this.commentsToOperator !== '') {
                this.formArray.controls[this.currentIndex].value.correcciones = this.commentsToOperator
            } else if(this.commentsToOperator !== '') {
                this.formArray.controls[this.currentIndex].value.correcciones = this.commentsToOperator
            }

            let lastForm = {
                "lista-no-conformidades": this.formArray.value
            }
            setTimeout(() => {
                this.communication.emitEvent({
                    identifier: 'set-values',
                    form: lastForm
                })
                
                this.commentsToOperator = this.formArray.controls[index].value.correcciones
        
                this.currentIndex = index
        
                setTimeout(() => {
                    this.communication.emitEvent({
                        identifier: 'validate-form-nc',
                        index: index
                    })
                }, 100);
            }, 100);

            console.log(this.formArray.controls[this.currentIndex].value)
        } else {
            this.currentIndex = index

            setTimeout(() => {
                this.communication.emitEvent({
                    identifier: 'validate-form-nc',
                    index: index
                })
            }, 100);
        }

        this.communication.emitEvent({
            identifier: 'update-substance-index',
            substanceIndex: index
        })
    }

    deleteGroup(index) {
        this.formArray.removeAt(index)
        let keys = []
        for (var i in this.formArray.controls) {
            const formGroup: FormGroup = this.formArray.controls[i] as FormGroup
            keys.push(Object.keys(formGroup.controls))
        }
        this.keys = keys
        this.currentIndex = this.formArray.length - 1

        let forms = JSON.parse(localStorage.getItem("NCF"))
        if(forms) {
            if(forms.length !== 0) {
                for(var j in forms) {
                    forms[j].used = false
                }
                for(var i in this.formArray.value) {
                    const item = this.formArray.value[i]
                    for(var j in forms) {
                        if(item.no_conformidad_evidencia_a_generar_form && item.no_conformidad_evidencia_a_generar_form === forms[j].formname) {
                            forms[j].used = true
                        }
                    }
                }
                localStorage.setItem("NCF", JSON.stringify(forms))
            }
        }
    }

    copy(event) {
        let index = event.target.selectedIndex - 1

        this.formArray.controls[this.currentIndex].value.reconocimiento_uso = this.formArray.controls[index].value.reconocimiento_uso
        
        let lastForm = {}
        let form = []
        for (var i in this.formArray.controls) {
            const obj = this.formArray.controls[i]
            form.push(obj.value)
            lastForm[this.attributes.group_name] = form
        }
        //console.log(lastForm, "lastForm")

        setTimeout(() => {
            this.communication.emitEvent({
                identifier: 'set-values',
                form: lastForm
            })
        }, 100);
    }

    search = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            tap(() => this.searching = true),
            switchMap(term =>
            this.substancesService.searchSubstancesOperator(term).pipe(
                tap(() => this.searchFailed = false),
                catchError(() => {
                this.searchFailed = true;
                return of([]);
                }))
            ),
            tap(() => this.searching = false)
        )

    formatter = (x: {code: string, name: string} ) => null

    async loadSubstance(event) {
        //console.log(event)
        //console.log(this.formArray)

        /*let controls = this.formArray.controls.length
        for (let i = 0; i < controls; i++) {
            this.deleteGroup(i)            
        }*/

        await event.item.substances.forEach(element => {
            element.uso_abono_fungicida_etc = ""
            element.cultivo_area_aplicacion = ""
        });

        let lastForm = {}
        lastForm[this.attributes.group_name] = event.item.substances

        //console.log(lastForm, "lastForm")

        setTimeout(() => {
            this.communication.emitEvent({
                identifier: 'set-values',
                form: lastForm
            })
        }, 100);

        this.changeIndex(0)
    }

    save(index){
        this.communication.emitEvent({
            identifier: 'save-substance',
            substanceIndex: index
        })
    }

    updateCheckbox(currentIndex, id){
        var forms = this.formArray.controls[currentIndex].value.forms

        for(var i in forms) {
            if(forms[i].id === id) {
                forms[i].selected = !forms[i].selected
                break
            }
        }

        this.formArray.controls[currentIndex].patchValue("forms", forms)
        this.formArray.controls[currentIndex].value.correcciones = this.commentsToOperator

        let lastForm = {
            "lista-no-conformidades": this.formArray.value
        }
        setTimeout(() => {
            this.communication.emitEvent({
                identifier: 'set-values',
                form: lastForm
            })
        }, 100);
    }

    updateCorrecciones(event, focusOut=false) {
        this.commentsToOperator = event.target.value

        if(focusOut && this.commentsToOperator !== "" && this.formArray.controls[this.currentIndex].value.correcciones !== this.commentsToOperator) {          
            clearTimeout(this.timer)

            this.timer = setTimeout(() => {
                this.changeIndex(this.currentIndex)
            }, 1000);
        }
    }

    getRequiresAction(index) {
        if(this.formArray.controls[this.currentIndex].value.hasOwnProperty("ncNotificationSent")) {
            if(this.formArray.controls[index].value.ncNotificationSent) {
                return this.formArray.controls[index].value.requiresAction
            } else {
                let evaluationId = this.form.value["lista-no-conformidades"][0].inspectionId
                var requiresAction = JSON.parse(localStorage.getItem("requiresAction"+evaluationId))

                return requiresAction[index]
            }
        }
    }

    requiresAction(index) {
        let evaluationId = this.form.value["lista-no-conformidades"][0].inspectionId
        var requiresAction = JSON.parse(localStorage.getItem("requiresAction"+evaluationId))

        requiresAction[index] = !requiresAction[index]

        localStorage.setItem("requiresAction"+evaluationId, JSON.stringify(requiresAction))
        setTimeout(() => {
            this.communication.emitEvent({
                identifier: 'validate-actions'
            })
        }, 100);
        
        if(this.formArray.controls[this.currentIndex].value.correcciones !== '' && this.commentsToOperator === '') {
            this.commentsToOperator = this.formArray.controls[this.currentIndex].value.correcciones
        }

        if(this.currentIndex === index) {
            this.formArray.controls[this.currentIndex].value.correcciones = this.commentsToOperator
        } else {
            this.formArray.controls[index].value.correcciones = this.formArray.controls[index].value.correcciones
        }

        let lastForm = {
            "lista-no-conformidades": this.formArray.value
        }
        setTimeout(() => {
            this.communication.emitEvent({
                identifier: 'set-values',
                form: lastForm
            })
        }, 100);
    }

    async open(name: string, formname, formId, isNC, isEvaluation, index, version = 0) { 
        let evaluationId = this.form.value["lista-no-conformidades"][0].inspectionId 
        let signs = JSON.parse(localStorage.getItem("signs"+evaluationId))

        var signatureUrlOperator = ''
        var signatureUrlInspector = ''
        var signatureUrlResponsible = ''
        var signatureUrlReviewer = ''
        var signatureUrlDictator = ''

        var tmpOperator = await this.customersService.getSignature(signs.idOperador).toPromise().catch(error => console.log(error))
        tmpOperator 
            ? tmpOperator.success 
                ? signatureUrlOperator = tmpOperator.file 
                : '' 
            : ''

        var tmpInspector = await this.usersService.getSignature(signs.idInspector).toPromise().catch(error => console.log(error))
        tmpInspector 
            ? tmpInspector.success 
                ? signatureUrlInspector = tmpInspector.file 
                : '' 
            : ''

        var tmpResponsible = await this.usersService.getSignature(signs.idResponsible).toPromise().catch(error => console.log(error))
        tmpResponsible 
            ? tmpResponsible.success 
                ? signatureUrlResponsible = tmpResponsible.file 
                : '' 
            : ''

        var tmpReviewer = await this.usersService.getSignature(signs.idReviewer).toPromise().catch(error => console.log(error))
        tmpReviewer 
            ? tmpReviewer.success 
                ? signatureUrlReviewer = tmpReviewer.file 
                : '' 
            : ''


        var nameDictator = ''      
        if(isNC) {
            nameDictator = this.form.value["lista-no-conformidades"][0].dictator.dictator
            signatureUrlDictator = this.form.value["lista-no-conformidades"][0].dictator.signature
        }

        console.log(name, formname, formId, isNC, isEvaluation, index, version)

        formId = parseInt(formId)
        if(version !== 0 ) {
            if(version === 1) {
                formId = 68
            } else if(version === 2) {
                formId = 268
            }
        }


        const modalRef = this._modalService.open(MODALS[name], { centered: true, backdropClass: 'modal-backdrop', size: 'xl' });
        modalRef.componentInstance.name = formname
        modalRef.componentInstance.formId = formId
        modalRef.componentInstance.reviewId = evaluationId
        modalRef.componentInstance.showReview = false
        modalRef.componentInstance.showEval = false
        modalRef.componentInstance.isNC = isNC
        modalRef.componentInstance.isEvaluation = isEvaluation
        modalRef.componentInstance.indexRFYD = index
        switch(formId) {
            /* version 1 */
            case 0:
            case 1:
            case 2:
            case 4:
            case 12:
            case 14:
            case 16:
            case 18:
            case 19:
            case 22:
            case 23:
            case 24:
            case 25:
            case 30:
            case 32:
            case 51:
            case 54:
            case 56:
            case 59:
            case 61:
            case 63:
            /* version 2 */
            case 200:
            case 201:
            case 202:
            case 204:
            case 212:
            case 214:
            case 216:
            case 218:
            case 219:
            case 222:
            case 223:
            case 224:
            case 225:
            case 230:
            case 232:
            case 251:
            case 254:
            case 256:
            case 259:
            case 261:
            case 263:
            case 286:
            case 287:
            case 288:
            case 289:
            case 290:
                modalRef.componentInstance.signatureUrl = signatureUrlOperator
                modalRef.componentInstance.signatureUrl2 = signatureUrlReviewer
                break
            /* version 1 */
            case 40:
            case 50:
            case 66:
            /* version 2 */
            case 240:
            case 250:
            case 266:
                modalRef.componentInstance.signatureUrl = signatureUrlOperator
                modalRef.componentInstance.signatureUrl2 = signatureUrlInspector
                break
            /* version 1 */
            case 9:
            case 10:
            case 11:
            case 17:
            /* version 2 */
            case 209:
            case 210:
            case 211:
            case 217:
            case 278:
                modalRef.componentInstance.signatureUrl = signatureUrlResponsible
                modalRef.componentInstance.signatureUrl2 = signatureUrlOperator
                break
            /* version 1 */
            case 68:
            /* version 2 */
            case 268:
                modalRef.componentInstance.signatureUrl = signatureUrlDictator
                break
            default:
                modalRef.componentInstance.signatureUrl = signatureUrlOperator
                break
        }
        modalRef.componentInstance.dictator = nameDictator
        modalRef.result.then(res => {
            if (res) {
            }
        });
    }
    
    getCommentsOperator(value) {
        var message = ''
        var hash = {};
        var newValue = value.filter(o => hash[o.message] ? false : hash[o.message] = true);

        for(var i in newValue) {
            message += value[i].message + "\n"
        }

        message = message.substr(0, message.length-1)

        return message
    }

    getFilename(url: string) {
        if (url.constructor.name !== 'String') {
            return ''
        }
        const parts = url.split("/")
        const name = parts[parts.length - 1]
        return name
    }
}