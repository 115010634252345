import { AlertService } from './../../../../core/services/alert.service';
import { RequestsService } from '@app/core/services';
import { ControlCommunicationService } from './../../../services/control-communication.service';
import { FormBuilderComponent } from './../../../form-builder.component';
import { FormGroup, FormControl, FormArray, AbstractControl } from '@angular/forms';
import { UnknownParams, ControlElement } from './../../../interfaces/form.interface';
import { Component, OnInit, Input, OnChanges } from '@angular/core'
import { file } from '@rxweb/reactive-form-validators';

@Component({
    selector: 'file-upload-element',
    templateUrl: './file-upload.component.html'
})
export class FileUploadComponent implements OnInit {

    attributes: UnknownParams = {}
    validations: UnknownParams = {}

    @Input() form: FormGroup

    @Input() data: ControlElement

    uploadedFiles = []

    // inject parent FormBuilder Component in order to access FormGroup
    constructor(private parentControl: FormBuilderComponent, private communicationService: ControlCommunicationService, private requestService: RequestsService, private alertService: AlertService) {
        communicationService.changeEmmited$.subscribe(msg => {
            if (msg.identifier === 'set-values') {
                // if there are already uploaded files
                //console.log(this.form.get(`${this.attributes.name}`), this.attributes.name)
                const elem = this.form.get(`${this.attributes.name}`)
                if (this.isArray(elem.value) && elem.value.length > 0) {
                    this.uploadedFiles = elem.value
                } else if (!this.isArray(elem.value) && elem.value) {
                    this.uploadedFiles[0] = elem.value
                } else {
                    this.uploadedFiles = []
                }
                for (var i in this.uploadedFiles) {
                    const elem = this.uploadedFiles[i]
                    if (typeof elem === 'object' && Object.keys(elem).length === 0) {
                        this.uploadedFiles.splice(parseInt(i), 1)
                    } else if (typeof elem === 'object' && Object.keys(elem).length > 0) {
                        this.uploadedFiles[parseInt(i)] = this.joinObjectString(this.uploadedFiles[parseInt(i)]) 
                    }
                }
                // this.form.get(this.attributes.name).patchValue(this.uploadedFiles)
                // let name = this.attributes.name
                this.form.setControl(this.attributes.name, new FormControl(this.uploadedFiles))
            }
        })
    }

    ngOnInit(): void {
        // asign parent formGroup to local formGroup
        if (!this.form) { this.form = this.parentControl.formGroup }
        // iterate and access component attributes
        for (var i in this.data.attributes) {
            const attribute = this.data.attributes[i]
            this.attributes[attribute.name] = attribute.value
        }
        for (var i in this.data.validations) {
            const validation = this.data.validations[i]
            this.validations[validation.name] = validation.value
        }
        this.form.addControl(this.attributes.name, new FormControl(this.attributes.default ? this.attributes.default : ''))
        const elem = this.form.get(`${this.attributes.name}`)
        if (elem.value.constructor.name !== 'FileList') {
            if (this.isArray(elem.value) && elem.value.length > 0) {
                this.uploadedFiles = elem.value
            } else if (!this.isArray(elem.value) && elem.value) {
                this.uploadedFiles[0] = elem.value
            } else {
                this.uploadedFiles = []
            }
            for (var i in this.uploadedFiles) {
                const elem = this.uploadedFiles[i]
                if (typeof elem === 'object' && Object.keys(elem).length === 0) {
                    this.uploadedFiles.splice(parseInt(i), 1)
                } else if (typeof elem === 'object' && Object.keys(elem).length > 0) {
                    this.uploadedFiles[parseInt(i)] = this.joinObjectString(this.uploadedFiles[parseInt(i)]) 
                }
            }
            this.form.setControl(this.attributes.name, new FormControl(this.uploadedFiles))
        }
    }

    async onFileSelected(evt) {
        //console.log(this.form.get(this.attributes.name).value)

        /* Get files loaded */
        var files = null 
        files = Array.from(this.form.get(this.attributes.name).value)
        const tempFilesEvt = Array.from(evt.target.files)
        for(var i in tempFilesEvt) {
            files.push(tempFilesEvt[i])
        }
        /* Delete duplicated files */
        files = files.filter((item,index)=>{
            return files.indexOf(item) === index;
        })

        const size = 1048576 * 10 //1048576 bytes * x MB
        let archivos = ''
        let tamañoExcede = false
        //console.log(files)

        /* Validate if files are less than 10MB */
        for (let i = 0; i < files.length; i++) {
            if (files[i].size >= size) {
                archivos += files[i].name + ", "
                tamañoExcede = true
            }
            //console.log(files[i].name, files[i].size)
        }
        archivos = archivos.substring(0, (archivos.length - 2))
        //console.log(archivos, tamañoExcede)

        /* Validate if exist files less than 10MB */
        if (!tamañoExcede) {
            var list = null
            //console.log(evt.target.files, this.form.get(this.attributes.name).value)

            if(this.form.get(this.attributes.name).value.length !== 0) {
                list = Array.from(this.form.get(this.attributes.name).value)
                
                var listTemp = Array.from(evt.target.files)
                for(var i in listTemp){
                    list.push(listTemp[i])
                }
            } else {
                list = Array.from(evt.target.files)
            }

            let hash = {};
            list = list.filter(o => hash[o.name] ? false : hash[o.name] = true);
            //console.log(list)

            //console.log("archivos", list)
            evt.target.value = null
            for (var i in this.uploadedFiles) {
                const f = this.uploadedFiles[i]
                if(!list.includes(f)) {
                    list.push(f)
                }
            }

            /* for to differentiate files on server and uploaded files */
            let prevList = []
            let newList = []
            for(var i in list) {
                //console.log(list[i])
                //console.log(typeof list[i] === 'string', typeof list[i] === 'object')
                //console.log("-----------------------------------------------------------------")

                if(typeof list[i] === 'string') {
                    prevList.push(list[i])
                } else if(typeof list[i] === 'object') {
                    newList.push(list[i])
                }
            }    
            //console.log(prevList, newList, list) 
            /* end for to differentiate */       

            if (this.validations.noMaxFiles) {
                this.form.get(this.attributes.name).setValue(list)
            } else if (!this.validations.unlimited && !this.validations.maxFiles && list.length > 5) {
                this.alertService.warn('No puedes subir más de 5 archivos', {
                    autoClose: true
                })
                
                list = prevList //list.slice(0, 5)
                this.form.get(this.attributes.name).setValue(list)
            } else if (!this.validations.unlimited && this.validations.maxFiles && list.length > this.validations.maxFiles) {
                this.alertService.warn(`No puedes subir más de ${this.validations.maxFiles} archivos`, {
                    autoClose: true
                })

                list = prevList //list.slice(0, this.validations.maxFiles)
                this.form.get(this.attributes.name).setValue(list)
            } else if(this.validations.unlimited && newList.length > this.validations.maxFiles) {
                this.alertService.warn(`No puedes subir más de ${this.validations.maxFiles} archivos por subida`, {
                    autoClose: true
                })

                newList = newList.slice(0, this.validations.maxFiles)

                list = prevList.concat(newList)
                this.form.get(this.attributes.name).setValue(list)
            } else {
                this.form.get(this.attributes.name).setValue(list)
            }
        }
        else {
            //console.log("excede el tamaño de 10 MB")
            let list = Array.from(this.form.get(this.attributes.name).value)

            this.alertService.warn(`Los archivos: ${archivos}. Exceden los 10 MB`)

            list = []
            this.form.get(this.attributes.name).patchValue(list)
        }    

        //console.log("archivos final", this.form.get(this.attributes.name).value)
    }

    replace() {
        this.form.get(this.attributes.name).setValue('')
    }

    isArray(item) {
        return Array.isArray(item)
    }

    getFilename(url: string) {
        if (url.constructor.name !== 'String') {
            return ''
        }
        const parts = url.split("/")
        const name = parts[parts.length - 1]
        return name
    }

    deleteFile(filename: string) {
        this.requestService.deleteFile(filename).subscribe(data => {
            for (var i in this.uploadedFiles) {
                const file = this.uploadedFiles[i]
                if (this.getFilename(file) === filename) {
                    this.uploadedFiles.splice(parseInt(i), 1)
                    this.communicationService.emitEvent({
                        identifier: 'reload'
                    })
                }
            }
        }, error => {
            for (var i in this.uploadedFiles) {
                const file = this.uploadedFiles[i]
                if (this.getFilename(file) === filename) {
                    this.uploadedFiles.splice(parseInt(i), 1)
                }
            }
        })
    }

    removeUploadingFile(file: File) {
        let files: File[] = this.form.get(this.attributes.name).value
        for (var i in files) {
            const f = files[i]
            if (f.name === file.name) {
                files.splice(parseInt(i), 1)
                break
            }
        }
        this.form.get(this.attributes.name).setValue(files)
    }

    joinObjectString(obj: object) {
        let result = ""
        for (var i in Object.keys(obj)) {
            if (typeof Object.keys(obj)[parseInt(i)] !== 'string') continue
            result = `${result}${obj[Object.keys(obj)[parseInt(i)]]}`
        }
        return result
    }
}