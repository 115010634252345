<div class="modal-header">
  <h4 class="modal-title" id="modal-title">Acuerdo para la Certificación</h4>
  <button
    type="button"
    class="close"
    aria-label="Close button"
    aria-describedby="modal-title"
    (click)="modal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p class="text-center text-muted">{{ name }}</p>
  <!-- Contracts V1 -->
  <div *ngIf="isMX && version === '1'">
    <contract-mx
      [date]="date"
      [customer]="customer"
      [metrocert]="metrocert"
      [signOperator]="signOperator"
    ></contract-mx>
  </div>
  <div *ngIf="isCAN && version === '1'">
    <contract-can
      [date]="date"
      [customer]="customer"
      [metrocert]="metrocert"
      [signOperator]="signOperator"
    ></contract-can>
  </div>
  <!-- Contracts V2 -->
  <div *ngIf="isMX && version === '2'">
    <contract-mx-v2
      [date]="date"
      [customer]="customer"
      [metrocert]="metrocert"
      [signOperator]="signOperator"
    ></contract-mx-v2>
  </div>
  <div *ngIf="isCAN && version === '2'">
    <contract-can-v2
      [date]="date"
      [customer]="customer"
      [metrocert]="metrocert"
      [signOperator]="signOperator"
    ></contract-can-v2>
  </div>
</div>
<div class="modal-footer">
  <button type="button" (click)="generatePdf('open')" class="btn btn-outline-dark">
    Abrir PDF
  </button>
  <!-- <button type="button" (click)="generatePdf('download')" class="btn btn-outline-dark">
    Descargar PDF
  </button>
  <button type="button" (click)="generatePdf('print')" class="btn btn-outline-dark">
    Imprimir PDF
  </button> -->
  <button
    type="button"
    class="btn btn-outline-danger"
    (click)="modal.dismiss('cancel click')"
  >
    Cancelar
  </button>
  <button
    [disabled]="loading"
    type="button"
    ngbAutofocus
    class="btn btn-dark"
    (click)="createContract()"
  >
    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
    Crear contrato
  </button>
</div>
