import { FormGroup } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core'
import { ContainerElement, UnknownParams, ControlElement } from '../../../interfaces/form.interface'

@Component({
    selector: 'table-container',
    templateUrl: './table.component.html'
})
export class SectionTableComponent implements OnInit {

    @Input() data: ContainerElement
    @Input() form: FormGroup

    attributes: UnknownParams = {}

    constructor() {}

    ngOnInit() {
        for (var i in this.data.attributes) {
            const attribute = this.data.attributes[i]
            this.attributes[attribute.name] = attribute.value
        }
    }

}