import { ControlCommunicationService } from './../../form-builder/services/control-communication.service';
import { FormGroup, FormArray } from '@angular/forms';
import { Component, Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient, HttpParams, HttpEventType } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, tap, switchMap } from 'rxjs/operators';

import { AuthenticationService } from '@app/core/services'

const WIKI_URL = 'https://en.wikipedia.org/w/api.php';
const PARAMS = new HttpParams({
});

@Injectable({ providedIn: 'root' })
export class RequestsService {
    constructor(
        private http: HttpClient, 
        private communication: ControlCommunicationService,
        private authenticationService: AuthenticationService
    ) { }

    search(term: string) {
        if (term === '') {
            return of([]);
        }
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get(`${environment.apiUrl}/customers/search/${term}`, { headers }).pipe(
            map(response => {
                //console.log(response['data'])
                return response['data']
            })
        );
    }

    createApplication(selectedOrderIds, values, model, destinoText) {
        const body = {
            operatorId: model.Id_Operator,
            applicationType: values.type,
            operatorType: values.typeCustomer,
            destination: destinoText,
            date: this.formatDate(new Date(values.date.year, values.date.month - 1, values.date.day)),
            scopes: selectedOrderIds
        }
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.post(`${environment.apiUrl}/applications/`, body, { headers })
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    getApplicationsList() {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/applications/list`, { headers });
    }

    getForms(variant) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/applications/forms/${variant}`, { headers });
    }
    getFormsByRecordId(recordId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/applications/${recordId}`, { headers });
    }

    updateApplicationType(recordId, type) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        const payload = {
            applicationType: type
        }

        return this.http.patch<any>(`${environment.apiUrl}/applications/updateTypeApplication/${recordId}`, payload, {headers});
    }

    getApplicationsDone(recordId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        }

        return this.http.get<any>(`${environment.apiUrl}/applications/done/${recordId}`, {headers});
    }

    getFormPreview(recordId, formId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        }

        return this.http.get<any>(`${environment.apiUrl}/applications/preview/${recordId}/form/${formId}`, {headers})
    }

    signDocument(recordId, formId){
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        const payload = {}

        return this.http.patch<any>(`${environment.apiUrl}/applications/preview/${recordId}/form/${formId}/sign`, payload, {headers});
    }

    signApplication(recordId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        const payload = {}

        return this.http.patch<any>(`${environment.apiUrl}/applications/${recordId}/sign`, payload, { headers });
    }

    approveApplication(recordId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        const payload = {}

        return this.http.patch<any>(`${environment.apiUrl}/applications/${recordId}/approve`, payload, { headers });
    }

    completeApplication(recordId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        const payload = {}

        return this.http.patch<any>(`${environment.apiUrl}/applications/${recordId}/complete`, payload, { headers });
    }

    deleteApplication(recordId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.delete<any>(`${environment.apiUrl}/applications/${recordId}`, { headers });
    }

    getForm(recordId, formId, ncsInspectionId = 0) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/applications/${recordId}/${ncsInspectionId}/form/${formId}`, { headers });
    }

    saveForm(recordId, formId, form, ncsInspectionId = 0) {
        if(formId === 13 || formId === 213) {
            for(var i in form) {
                for(var j in form[i]) {
                    form[i][j]["isSaved"] = "No"
                }
            }
        } else if(formId === 1 || formId === 201) {
            for(var i in form) {
                for(var j in form[i]) {
                    if(form[i][j]["ubicacion_longitud"] && form[i][j]["ubicacion_longitud"] > 0) {
                        form[i][j]["ubicacion_longitud"] = form[i][j]["ubicacion_longitud"] * (-1)
                    }

                    form[i][j]["ubicacion_latitud"] = '' + parseFloat(form[i][j]["ubicacion_latitud"]).toFixed(6)
                    form[i][j]["ubicacion_longitud"] = '' + parseFloat(form[i][j]["ubicacion_longitud"]).toFixed(6)
                }
            }
        }

        let body = {
            submission: []
        }

        for (var i in Object.keys(form)) {
            const key = Object.keys(form)[i]
            body.submission.push({
                name: key,
                value: form[key]
            })
        }

        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.post<any>(`${environment.apiUrl}/applications/${recordId}/${ncsInspectionId}/form/${formId}`, body, { headers });
    }

    deleteForm(recordId, formId, ncsInspectionId = 0) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.delete<any>(`${environment.apiUrl}/applications/${recordId}/${ncsInspectionId}/form/${formId}`, { headers });
    }

    uploadContract(recordId, body) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Authorization': jwtToken
        };

        return this.http.post(`${environment.apiUrl}/applications/uploadContract/${recordId}`, body, { headers })
    }

    getContract(recordId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get(`${environment.apiUrl}/applications/contract/${recordId}`, { headers })
    }

    saveFormFiles(key: string, files, formId, recordId) {
        let body = new FormData()

        for (var i in files) {
            const file = files[i]
            if (file.constructor.name === 'File') {
                let f = file as File
                body.append(f.name, f, f.name)
            }
        }

        body.append('formId', formId)
        body.append('recordId', recordId)
        body.append('fieldname', key)

        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Authorization': jwtToken
        };

        return this.http.post<any>(`${environment.apiUrl}/applications/upload`, body, { headers, reportProgress: true, observe: 'events' });

    }

    deleteFile(filename: String) {
        return this.http.delete<any>(`${environment.apiUrl}/applications/files/${filename}`);
    }

    async changeFilesIntoUrls(formGroup: FormGroup, formId: number, recordId: number) {
        let fg = formGroup
        const keys = Object.keys(fg.controls)
        for (var i in Object.keys(fg.controls)) {
            const ctrl = fg.get(keys[i])
            const type = ctrl.constructor.name
            switch (type) {
                case 'FormArray':
                    const fArray = ctrl as FormArray
                    for (var j in fArray.controls) {
                        await this.changeFilesIntoUrls(fArray.controls[j] as FormGroup, formId, recordId)
                    }
                    break;
                case 'FormControl':
                    const ctrlType = ctrl.value ? ctrl.value.constructor.name : null
                    if (ctrlType === 'Array') {
                        const fileList = ctrl.value
                        // console.log(fileList)
                        let canUpload = false
                        for (var i in fileList) {
                            if (fileList[i].constructor.name === 'File') {
                                canUpload = true
                                break
                            }
                        }
                        if (canUpload) {
                            await this.authenticationService.getMe().toPromise()
                            let res = await this.saveFormFiles(keys[i], fileList, formId, recordId).pipe(map(event => {
                                if (event.type === HttpEventType.UploadProgress) {
                                    let p = Math.round((event.loaded/event.total ) * 100)
                                    this.communication.emitEvent({
                                        identifier: 'uploading',
                                        percentage: p
                                    })
                                }
                                if (event.type === HttpEventType.Response) {
                                    return event
                                }
                            })).toPromise()
                            let alreadyIn = []
                            for (var i in fileList) {
                                const f = fileList[i]
                                if (typeof f === 'string') {
                                    alreadyIn.push(f)
                                }
                            }
                            for (var i in res.body.urls) {
                                alreadyIn.push(res.body.urls[i])
                            }
                            for (var i in alreadyIn) {
                                if (typeof alreadyIn[i] === 'object' && Object.keys(alreadyIn[i]).length === 0) {
                                    alreadyIn.splice(parseInt(i), 1)
                                }
                            }
                            ctrl.setValue(alreadyIn)
                        }
                    }
                    break
                default:
                    break
            }
        }
        return fg
    }

}